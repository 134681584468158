<template>
  <el-card
    class="box-card"
    shadow="hover"
    style="width: 200px"
    @click="handleClick"
  >
    <div>
      <div style="margin-bottom: 10px; color: #909399">
        <span>{{ title }}</span>
      </div>
      <div class="text item" style="font-size: 25px">
        {{ value }}
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: '',
    },
    params: {
      type: Object,
      default: () => ({
        type: '',
        value: '',
      }),
    },
  },
  data() {
    return {}
  },
  methods: {
    handleClick() {
      if (this.params.type) {
        let params = { page: 0, ...this.params }
        this.$store.commit('devices/SET_PARAMS', params)
        this.$store.dispatch('devices/getByStatusApi', {
          params,
        })
      }
    },
  },
}
</script>

<style></style>
