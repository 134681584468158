import moment from 'moment'
import _ from 'lodash'
export function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      (Array.isArray(obj[propName]) && obj[propName].length === 0)
    ) {
      delete obj[propName]
    }
  }
  return obj
}
export function isObjectNull(obj) {
  let result = false
  Object.values(obj).every((value) => {
    if (value === null || (Array.isArray(value) && value.length === 0)) {
      result = true
    }
  })
  return result
}
export function findByValue(obj, value) {
  let found = false
  for (var propName in obj) {
    if (obj[propName] === value) {
      found = true
    }
  }
  return found
}
export function arrayJoin(obj) {
  for (var propName in obj) {
    if (Array.isArray(obj[propName]) && obj[propName].length !== 0) {
      obj[propName] = _.join(obj[propName], ',')
    }
  }
  return obj
}

export function reduceTimeRange(events) {
  let temp = []
  if (!events.length) return
  let accu = events.reduce((acc, vc) => {
    if (overlap(acc, vc)) {
      acc.start = this.$moment
        .min(moment(acc.start), moment(vc.start))
        .format('YYYY-MM-DD HH:mm')
      acc.end = moment
        .max(moment(acc.end), moment(vc.end))
        .format('YYYY-MM-DD HH:mm')
    } else {
      temp.push(acc)
      acc = vc
    }
    return acc
  })
  temp.push(accu)
  return temp
}
export function overlap(e1, e2) {
  return (
    moment(e1.end) >= moment(e2.start) && moment(e1.start) <= moment(e2.end)
  )
}
export function overlapInside(e1, e2) {
  return (
    overlap(e1, e2) &&
    moment(e1.start) <= moment(e2.start) &&
    moment(e1.end) >= moment(e2.end)
  )
}

export function compare(a, b) {
  if (moment(a.start) < moment(b.start)) {
    return -1
  }
  if (moment(a.start) > moment(b.start)) {
    return 1
  }
  return 0
}
export function getRandomColor() {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
function hashCode(str) {
  // java String#hashCode
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}
export function stringToColour(str) {
  var hash = hashCode(str)
  var colour = '#'
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff
    let length = ('00' + value.toString(16)).length
    colour += ('00' + value.toString(16)).substring(length - 2, length)
  }

  return colour
}
export function getStatusLabel(status) {
  let label = ''
  switch (status) {
    case 'moving':
      label = 'En mouvement'
      break
    case 'stopped':
      label = 'En arrêt'
      break
    case 'parked':
      label = 'Garé'
      break
    case 'untracked':
      label = 'Non suivi'
      break
    default:
      label = 'Non suivi'
      break
  }
  return label
}
export function getRoleLabel(role) {
  let label = ''
  switch (role) {
    case 'ORG_ADMIN':
      label = 'Administrateur'
      break
    case 'ORG_MANAGER':
      label = 'Manager'
      break
    case 'ORG_USER':
      label = 'Utilisateur'
      break
    default:
      label = ''
      break
  }
  return label
}
export function getDeviceStatus(status) {
  let label = ''
  switch (status) {
    case 'online':
      label = 'Connecté'
      break
    case 'offline':
      label = 'Non connecté'
      break
    case 'inactive':
      label = 'Inactif'
      break
    default:
      label = ''
      break
  }
  return label
}

function compareObject(a, b) {
  if (a.fleet && !b.fleet) {
    return -1
  }
  if (!a.fleet && b.fleet) {
    return 1
  }
  return 0
}

export function groupByFleet(array) {
  array.sort(compareObject)
  var result = []
  for (var i = 0; i < array.length; i++) {
    var added = false
    if (array[i]['fleet'] == null) {
      result.push(array[i])
      continue
    }
    if (result.length == 0) {
      var entry = { children: [] }
      entry['id'] = array[i]['fleet']['id']
      entry['name'] = array[i]['fleet']['name']
      entry.children.push(array[i])
      result.push(entry)
    } else {
      for (var j = 0; j < result.length; j++) {
        if (result[j]['id'] == array[i]['fleet']['id']) {
          result[j].children.push(array[i])
          added = true
          break
        }
      }
      if (!added) {
        entry = { children: [] }
        entry['id'] = array[i]['fleet']['id']
        entry['name'] = array[i]['fleet']['name']
        entry.children.push(array[i])
        result.push(entry)
      }
    }
  }
  return result
}

export function convertHMS(value) {
  const sec = parseInt(value, 10) // convert value to number if it's string
  let days = Math.floor(sec / 86400) // get days
  let hours = Math.floor((sec - days * 86400) / 3600) // get hours
  let minutes = Math.floor((sec - days * 86400 - hours * 3600) / 60) // get minutes

  let dhm = ''

  if (days > 0) {
    dhm += days + 'j '
  }
  if (hours > 0) {
    dhm += hours + 'h '
  }
  if (minutes > 0) {
    dhm += minutes + 'min'
  }
  if (dhm == '') {
    dhm = value + 'sec'
  }
  return dhm // Return is HH : MM : SS
}

export function checkBrowser() {
  // Get the user-agent string
  let userAgentString = navigator.userAgent

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf('Chrome') > -1

  // Detect Internet Explorer
  let IExplorerAgent =
    userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1

  // Detect Firefox
  let firefoxAgent = userAgentString.indexOf('Firefox') > -1

  // Detect Safari
  let safariAgent = userAgentString.indexOf('Safari') > -1

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false

  // Detect Opera
  let operaAgent = userAgentString.indexOf('OP') > -1

  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) chromeAgent = false

  document.querySelector('.output-safari').textContent = safariAgent
  document.querySelector('.output-chrome').textContent = chromeAgent
  document.querySelector('.output-ie').textContent = IExplorerAgent
  document.querySelector('.output-opera').textContent = operaAgent
  document.querySelector('.output-firefox').textContent = firefoxAgent
}
