<template>
  <div class="common-layout">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-space wrap :size="'large'">
          <Card
            class="text-secondary"
            :title="'Nombre Total'"
            :value="insights.count"
          />
          <Card
            class="text-secondary"
            :title="'Devices Attachés'"
            :value="insights.unattached"
          />
          <div class="box blue"
            ><Card
              class="text-info"
              :title="'En ligne'"
              :value="insights.status.online"
              @click="handleStatus('online')"
          /></div>
          <div class="box yellow">
            <Card
              class="text-warning"
              :title="'Hors ligne'"
              :value="insights.status.offline"
              @click="handleStatus('offline')"
          /></div>
        </el-space>
      </el-col>
      <el-col :span="12">
        <el-space wrap :size="'large'">
          <div class="box green">
            <Card
              class="text-success"
              :title="'En mouvement'"
              :value="insights.motionState.moving"
              @click="handleMotionStatus('moving')"
          /></div>

          <div class="box red"
            ><Card
              class="text-danger"
              :title="'En arrêt'"
              :value="insights.motionState.stopped"
              @click="handleMotionStatus('stopped')"
          /></div>

          <div class="box bluee"
            ><Card
              class="text-info"
              :title="'Stationnés'"
              :value="insights.motionState.parked"
              @click="handleMotionStatus('parked')"
          /></div>
          <div class="box yelloww"
            ><Card
              class="text-warning"
              :title="'Non suivi'"
              :value="insights.motionState.untracked"
              @click="handleMotionStatus('untracked')"
          /></div>
        </el-space>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-card
          class="box-card"
          shadow="hover"
          :body-style="{ height: '380px' }"
        >
          <div class="clearfix">
            <span style="color: #6c757d">Nombre des boitiers par marque</span>
          </div>

          <div class="card-body">
            <c-chart-pie
              :data="{
                labels: [
                  'ALPHATECH',
                  'GT06',
                  'ALBATROSS',
                  'TELTONIKA',
                  'ODACEL',
                ],
                datasets: [
                  {
                    backgroundColor: [
                      '#f87979',
                      '#63c2de',
                      '#fec42f',
                      '#ffa000',
                      'red',
                    ],
                    hoverBackgroundColor: [
                      '#f87979',
                      '#63c2de',
                      '#fec42f',
                      '#ffa000',
                      ' red',
                    ],
                    data: dataDeviceByMark,
                  },
                ],
              }"
              :options="options"
              :width="width"
              :height="height"
            ></c-chart-pie>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card
          class="box-card"
          shadow="hover"
          :body-style="{ height: '380px' }"
        >
          <div class="barChart">
            <CChart
              type="bar"
              :width="200"
              :height="150"
              :data="{
                labels: [
                  'CAN',
                  'FM',
                  'FM23',
                  'FMB003',
                  'FMB020',
                  'FMB130',
                  'MINI',
                  'MINICAN',
                  'MONGI',
                  'VEGEO',
                ],
                datasets: [
                  {
                    label: 'nombre des boitiers par modèle',
                    backgroundColor: '#f87979',
                    data: dataDeviceByModel,
                  },
                ],
              }"
              labels="modèle"
            ></CChart>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Card from './Card.vue'
import _ from 'lodash'
import { CChartPie, CChart } from '@coreui/vue-chartjs'

export default {
  components: { Card, CChartPie, CChart },
  props: {},
  data() {
    return {
      options: {
        responsive: false,
        maintainAspectRatio: false,
      },
      width: 410,
      height: 300,
    }
  },

  computed: {
    ...mapState('devices', ['insights']),

    dataStatus() {
      if (_.get(this.insights, 'status', false)) {
        const { online, offline, unknown } = this.insights.status
        return [online, offline, unknown]
      } else return [0, 0, 0]
    },
    dataMotionStatus() {
      if (_.get(this.insights, 'motionState', false)) {
        const { moving, parked, stopped, untracked } = this.insights.motionState
        return [moving, parked, stopped, untracked]
      } else return [0, 0, 0]
    },
    dataDeviceByMark() {
      if (_.get(this.insights, 'deviceByMark', false)) {
        const { ALPHATECH, GT06, ALBATROSS, TELTONIKA, ODACEL } =
          this.insights.deviceByMark

        return [ALPHATECH, GT06, ALBATROSS, TELTONIKA, ODACEL]
      } else return [0, 0, 0, 0, 0]
    },
    dataDeviceByModel() {
      if (_.get(this.insights, 'deviceByModel', false)) {
        const {
          CAN,
          FM,
          FM23,
          FMB003,
          FMB020,
          FMB130,
          MINI,
          MINICAN,
          MONGI,
          VEGEO,
        } = this.insights.deviceByModel

        return [
          CAN,
          FM,
          FM23,
          FMB003,
          FMB020,
          FMB130,
          MINI,
          MINICAN,
          MONGI,
          VEGEO,
        ]
      } else return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
  },

  methods: {
    handleStatus(value) {
      let params = {
        status: value,
        page: 0,
        size: 20,
      }
      this.$store.commit('devices/SET_PARAMS', params)
      this.$store.dispatch('devices/getByStatusApi', {
        params,
      })
    },
    handleMotionStatus(value) {
      let params = {
        motionState: value,
        page: 0,
        size: 20,
      }
      this.$store.commit('devices/SET_PARAMS', params)
      this.$store.dispatch('devices/getByStatusApi', {
        params,
      })
    },
  },
}
</script>
<style>
.box {
  position: relative;
}
.box:before {
  position: absolute;
  left: -20px;
  content: '';
  height: 10px;
  width: 40px;
  margin-bottom: 15px;
}
.box.red:before {
  background-color: red;
  margin-left: 110px;
  margin-top: 25px;
  width: 40px;
  border-radius: 2px;
}
.box.yellow:before {
  background-color: rgb(238, 215, 10);
  margin-left: 120px;
  margin-top: 25px;
  border-radius: 2px;
}
.box.blue:before {
  background-color: rgb(61, 61, 240);
  margin-left: 110px;
  margin-top: 25px;
  width: 40px;
  border-radius: 2px;
}

.box.green:before {
  background-color: rgb(133, 240, 61);
  margin-left: 160px;
  margin-top: 25px;
  width: 40px;
  border-radius: 2px;
}

.box.bluee:before {
  background-color: rgb(61, 61, 240);
  margin-left: 128px;
  margin-top: 25px;
  width: 40px;
  border-radius: 2px;
}

.box.yelloww:before {
  background-color: rgb(238, 215, 10);
  margin-left: 116px;
  margin-top: 25px;
  border-radius: 2px;
}
.box-card {
  width: 420px;
}

.barChart {
  margin-top: 50px;
}
.card-body {
  margin-top: 16px;
}
</style>
